import React, { useEffect } from 'react'

import Newsletter from './Newsletter'
import Footer from './Footer'
import Setting from './Setting'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const SettingProfil = () => {
  const navigate = useNavigate(); 
  const { isAuth } = useSelector((state) => state.coach);

  useEffect(() => {
    if (!isAuth) navigate("/coach/login");
  }, [isAuth, navigate]); 
  return (
<>
<Setting/>
<Newsletter/>
<Footer/>
</>

)
}

export default SettingProfil