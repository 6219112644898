import React, { useEffect } from 'react'
import BarheaderProfil from './BarheaderProfil'
import { NavBar } from './NavBar'
import Edit from './Edit'
import Newsletter from './Newsletter'
import Footer from './Footer'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const EditProfile = () => {
  const navigate = useNavigate();

  const { isAuth } = useSelector((state) => state.coach);

  useEffect(() => {
    if (!isAuth) navigate("/coach/login");
  }, [isAuth, navigate]);
  return (
<>
<BarheaderProfil/>
       <NavBar/>
<Edit/>
<Newsletter/>
<Footer/>

</>  )
}

export default EditProfile