import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getImageUrl } from '../../index.js';
import './css/atelierDegus.css';
import image from '../../images/big_image_atelier.jpg';
import Newsletter from '../coach/Newsletter.js';
import Footer from '../coach/Footer.js';
import { GetAtelier } from '../../Redux/Slice/AtelierSlice.js';

const AtelierDeg = () => {
  const { ateliers, loading } = useSelector((state) => state.atelier); // State for workshops
  const { pubatelier } = useSelector((state) => state.pubatelier); // State for workshop publications
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAtelier()); // Fetch workshops
  }, [dispatch]);

  // Get the first publication details for a specific workshop
  const getPublicationDetails = (atelierId) => {
    if (!pubatelier || pubatelier.length === 0) {
      return { image: null, titre: null }; // No publications available
    }
    const publication = pubatelier.find((pub) => pub.ouner === atelierId);
    if (!publication) return { image: null, titre: null };
    return {
      image: publication.img ? getImageUrl(publication.img) : null,
      titre: publication.titre || 'Aucun titre disponible', // Default if no title
    };
  };

  if (loading) {
    // Show loading state
    return <div className="loading">Chargement des ateliers...</div>;
  }

  return (
    <>
      {/* Header Section */}
      <div
        className="ImagePlatforme"
        style={{
          position: 'relative',
          textAlign: 'center',
          height: '300px',
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          overflow: 'hidden',
        }}
      >
        <div style={{ paddingTop: '30px', fontSize: '40px' }}>
          <h3 className="Titre-Ate-Duge">Les Ateliers Dégustation Coaching</h3>
        </div>
      </div>

      {/* Workshops List */}
      <div className="atelier-Degus">
        <div className="atelierD-container">
          {ateliers.map((atelier) => {
            const { image: imageUrl, titre: publicationTitre } = getPublicationDetails(atelier._id);
            return (
              <div
                className="card-D"
                key={atelier._id}
                onClick={() => navigate(`/atelier_degustation/${atelier._id}`)} // Navigate to the workshop details
              >
                {/* Image */}
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={`Image de la publication`}
                    className="card-D-image"
                  />
                )}
                {/* Title */}
                <h3
                  className="card-D-titre"
                  dangerouslySetInnerHTML={{
                    __html: publicationTitre, // Safely render HTML content
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>

      {/* Newsletter and Footer */}
      <Newsletter />
      <Footer />
    </>
  );
};

export default AtelierDeg;
