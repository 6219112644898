import React, { useEffect } from "react";
import BarheaderAdmin from "../BarheaderAdmin";
import NavBarAdmin from "../NavBarAdmin";
import "./css/coachA.css";
import { Link, useNavigate } from "react-router-dom";
import Deconnection from "../Deconnection";
import { useSelector } from "react-redux";
const CoachA = () => {
  const navigate=useNavigate()
  const { isAuthadmin } = useSelector((state) => state.admin);

  useEffect(() => {
    if (!isAuthadmin) navigate('/admin/login');
  }, [isAuthadmin, navigate]);
  return (
<>

<BarheaderAdmin />
      <NavBarAdmin />
      <Deconnection/>
      <div className="ConsultCoach">
        <div className="ConsultCoachContainer">
          <Link to='/admin/Coachs/invisible'>
          <button className="AccueilCoach">Liste des coachs non validés sur la plateforme</button>
          </Link>
          <Link to='/admin/Coachs/visible'>
          <button className="AccueilCoach">Liste des coachs validés sur la plateforme </button>
          </Link>
         
        </div>
      </div>
      </>  )
}

export default CoachA