import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './css/ajouterevenement.css';
import BarheaderAdmin from '../BarheaderAdmin';
import NavBarAdmin from '../NavBarAdmin';
import { AddEvenement } from '../../../Redux/Slice/EvenementSlice';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import OverlayA from '../OverlayA';

const AjouterEvenement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const photoRef = useRef(null);
    const { isAuthadmin } = useSelector((state) => state.admin);

    const [Texte, setTexte] = useState('');
    const [formData, setFormData] = useState({
        titre: '',
        texte: '',
        lien: '',
        lieu: '',
        dates: '', 
    });
    useEffect(() => {
        if (!isAuthadmin) navigate('/admin/login');
      }, [isAuthadmin, navigate]);
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handlePartenaire = (event) => {
        event.preventDefault();

        // Vérification de la complétion de tous les champs requis
        if (!formData.titre || !Texte || !formData.lien || !formData.lieu || !formData.dates || !photoRef.current.files[0]) {
            alert("Tous les champs sont requis.");
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('titre', formData.titre);
        formDataToSend.append('texte', Texte);
        formDataToSend.append('lien', formData.lien);
        formDataToSend.append('lieu', formData.lieu);
        formDataToSend.append('dates', formData.dates);
        formDataToSend.append('photo', photoRef.current.files[0]);

        dispatch(AddEvenement(formDataToSend))
            .then(() => {
                navigate('/admin/Evenements');
            })
            .catch((error) => {
                console.error("Failed to add event: ", error);
            });
    };

    return (
        <>
            <BarheaderAdmin />
            <NavBarAdmin />
            <OverlayA />

            <div className="EvenementAjouter">
                <form className="EveAjouterContainer" onSubmit={handlePartenaire}>
                    <button className="buttonAccueilEvnt" onClick={() => navigate("/admin/Accueil")}>
                        Accueil
                    </button>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className='labelAddEvnt'>Titre :</label>
                        <input
                            type="text"
                            name="titre"
                            value={formData.titre}
                            onChange={handleInputChange}
                            className='inputAddEvnt'
                            required
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className='labelAddEvnt'>Texte :</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={formData.texte}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setTexte(data);
                            }}
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className='labelAddEvnt'>Lien :</label>
                        <input
                            type="text"
                            name="lien"
                            value={formData.lien}
                            onChange={handleInputChange}
                            className='inputAddEvnt'
                            required
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className='labelAddEvnt'>Lieu :</label>
                        <input
                            type="text"
                            name="lieu"
                            value={formData.lieu}
                            onChange={handleInputChange}
                            className='inputAddEvnt'
                            required
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className='labelAddEvnt'>Date :</label>
                        <input
                            type="text"
                            name="dates"
                            value={formData.dates}
                            onChange={handleInputChange}
                            className='inputAddEvnt'
                            required
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className='labelAddEvnt'>Photo :</label>
                        <input type="file" ref={photoRef} required className='fileAddEvnt' />
                    </div>

                    <button type="submit" className='ButtonAddEvnt'>
                        Envoyer
                    </button>
                </form>
            </div>
        </>
    );
};

export default AjouterEvenement;
