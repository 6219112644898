import React, { useEffect, useState } from "react";
import image from '../../images/big_image_atelier.jpg';
import "./css/ajouterAtelier.css";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL_back, getImageUrl } from "../..";
import { GetAtelier } from "../../Redux/Slice/AtelierSlice";
import { GetPublication } from "../../Redux/Slice/PubAtelierSlice";
import { AddList } from "../../Redux/Slice/ListSlice";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FacebookShareButton, LinkedinShareButton } from 'react-share';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import Alert from '@mui/material/Alert'; 

const FormAtelier = () => {
  const dispatch = useDispatch();
  const { ateliers } = useSelector((state) => state.atelier);
  const { pubatelier } = useSelector((state) => state.pubatelier);
  const [recentPublications, setrecentPublications] = useState([]);
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    tel: "",
    mail: "",
    entreprise: "",
    poste: "",
    question: "",
    nomEntrepriseOuCoach: "", // Added for entreprise name
  });
  
  const [showModal, setShowModal] = useState(false);
  const [selectedBub, setSelectedBub] = useState(null);

  // Alert state
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    dispatch(GetAtelier());
    dispatch(GetPublication());
  }, [dispatch]);

  useEffect(() => {
    if (pubatelier && pubatelier.length > 0) {
      const shuffledAtelier = pubatelier
        .slice()
        .sort(() => Math.random() - 0.5)
        .slice(0, 7);
      setrecentPublications(shuffledAtelier);
    }
  }, [pubatelier]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRadioChange = (e) => {
    setFormData({ ...formData, entreprise: e.target.value });
  };
  const validateForm = () => {
    const errors = {};
    if (!formData.nom) errors.nom = "Nom is required.";
    if (!formData.prenom) errors.prenom = "Prénom is required.";
    if (!formData.tel) errors.tel = "Numéro de téléphone is required.";
    if (!formData.mail) errors.mail = "Adresse mail is required.";
    if (!formData.entreprise) errors.entreprise = "Entreprise/Coach selection is required.";
    if (formData.entreprise === "Entreprise" && !formData.nomEntrepriseOuCoach) {
      errors.nomEntrepriseOuCoach = "Nom de l'entreprise is required.";
    }
    if (formData.entreprise === "Coach" && !formData.nomEntrepriseOuCoach) {
      errors.nomEntrepriseOuCoach = "Nom du coach is required.";
    }
    if (!formData.poste) errors.poste = "Poste is required.";
    if (formData.question.length > 500) errors.question = "Question exceeds 500 characters.";
    
    return errors;
  };
  
  const [errors, setErrors] = useState({});

  const handleAddAtelier = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    
    const { nom, prenom, tel, mail, entreprise, poste, question, nomEntrepriseOuCoach } = formData;
    const lastAtelier = ateliers.length > 0 ? ateliers[ateliers.length - 1] : null;
  
    dispatch(AddList({
      id: lastAtelier ? lastAtelier._id : null,
      data: {
        nom,
        prenom,
        tel,
        mail,
        entreprise,
        poste,
        proposition: question,
        nomEntrepriseOuCoach,
      }
    })).then(() => {
      setAlertMessage("Atelier added successfully!");
      setAlertOpen(true);
      setFormData({
        nom: "",
        prenom: "",
        tel: "",
        mail: "",
        entreprise: "",
        poste: "",
        question: "",
        nomEntrepriseOuCoach: "",
      });
      setErrors({});
    }).catch(() => {
      setAlertMessage("Failed to add atelier. Please try again.");
      setAlertOpen(true);
    });
  };
  
  const openModal = (pub) => {
    setSelectedBub(pub);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedBub(null);
  };
  const shareUrl = selectedBub ? `${BASE_URL_back}/atelier_D/${selectedBub._id}` : '';

  return (
    <>
      <div
        className="ImagePlatformeAtelier"
        style={{
          position: "relative",
          textAlign: "center",
          height: "300px",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <div style={{ paddingTop: "100px"}}>
          <h3 className="TitreAtelier">Inscription</h3>
        </div>
      </div>

      <div className="Add-Atelier">
        <div className="AddAtelier-container">
          <div className="left-Atelier">
            {recentPublications.map((pub, index) => (
              <div key={index} className="Atelier-Item" onClick={() => openModal(pub)} style={{ cursor: 'pointer' }}>
                <img src={getImageUrl(pub.img)} alt="atelier" className="atelier_image" />
                <h1
                  className="bublAtelier"
                  dangerouslySetInnerHTML={{
                    __html: pub.titre, // Safely render HTML content
                  }}
                />
              </div>
            ))}
          </div>
          <div className="right-Atelier">
            {ateliers.length > 0 && (
              <>
                <img
                  style={{ display: 'block', margin: '0 auto', width: '300px' }}
                  src={getImageUrl(ateliers[ateliers.length - 1].photo)}
                  alt="atelier"
                />
                <h3 className="AtelierTheme">{`Théme ${ateliers[ateliers.length - 1].num}`}</h3>
                <h3 className="AtelierTitre">{ateliers[ateliers.length - 1].titre}</h3>
                <h3 className="AtelierDate">{ateliers[ateliers.length - 1].date}</h3>
                <h3 className="AtelierHeure">{ateliers[ateliers.length - 1].heure}</h3>
                <h3 className="AtelierStatut">{ateliers[ateliers.length - 1].statut}</h3>
              </>
            )}
          <form onSubmit={handleAddAtelier} style={{marginTop:'50px'}}>
  <label className="labelAtelier">Nom:</label>
  <input
    type="text"
    name="nom"
    className="inputAtelier"
    value={formData.nom}
    onChange={handleInputChange}
  />
  {errors.nom && <p className="error-message">{errors.nom}</p>}
  
  <label className="labelAtelier">Prénom:</label>
  <input
    type="text"
    name="prenom"
    className="inputAtelier"
    value={formData.prenom}
    onChange={handleInputChange}
  />
  {errors.prenom && <p className="error-message">{errors.prenom}</p>}
  
  <label className="labelAtelier">Numéro de téléphone:</label>
  <input
    type="text"
    name="tel"
    className="inputAtelier"
    value={formData.tel}
    onChange={handleInputChange}
  />
  {errors.tel && <p className="error-message">{errors.tel}</p>}
  
  <label className="labelAtelier">Adresse mail:</label>
  <input
    type="text"
    name="mail"
    className="inputAtelier"
    value={formData.mail}
    onChange={handleInputChange}
  />
  {errors.mail && <p className="error-message">{errors.mail}</p>}
  
  <label className="labelAtelier">Entreprise/Coach:</label>
  <div className="radio">
    <input
      type="radio"
      id="entreprise"
      name="entreprise"
      value="Entreprise"
      onChange={handleRadioChange}
      checked={formData.entreprise === "Entreprise"}
    />
    <label htmlFor="entreprise">Entreprise</label>
  </div>
  <div className="radio">
    <input
      type="radio"
      id="coach"
      name="entreprise"
      value="Coach"
      onChange={handleRadioChange}
      checked={formData.entreprise === "Coach"}
    />
    <label htmlFor="coach">Coach</label>
  </div>
  {errors.entreprise && <p className="error-message">{errors.entreprise}</p>}
  
  {formData.entreprise === "Entreprise" && (
    <div>
      <label className="labelAtelier">Nom de l'entreprise:</label>
      <input
        type="text"
        name="nomEntrepriseOuCoach"
        className="inputAtelier"
        value={formData.nomEntrepriseOuCoach}
        onChange={handleInputChange}
      />
      {errors.nomEntrepriseOuCoach && <p className="error-message">{errors.nomEntrepriseOuCoach}</p>}
    </div>
  )}
  
  {formData.entreprise === "Coach" && (
    <div>
      <label className="labelAtelier">Nom du coach:</label>
      <input
        type="text"
        name="nomEntrepriseOuCoach"
        className="inputAtelier"
        value={formData.nomEntrepriseOuCoach}
        onChange={handleInputChange}
      />
      {errors.nomEntrepriseOuCoach && <p className="error-message">{errors.nomEntrepriseOuCoach}</p>}
    </div>
  )}
  
  <label className="labelAtelier">Poste:</label>
  <input
    type="text"
    name="poste"
    className="inputAtelier"
    value={formData.poste}
    onChange={handleInputChange}
  />
  {errors.poste && <p className="error-message">{errors.poste}</p>}
  
  <label>Ma question pour un des intervenants (maximum 5 lignes):</label>
  <textarea
    name="question"
    className="TextareaAtelier"
    value={formData.question}
    onChange={handleInputChange}
  />
  <label>500 caractères maximum.</label>
  {errors.question && <p className="error-message">{errors.question}</p>}
  <button type="submit" className="BoutonAtelier">Envoyer</button>
</form>

          </div>
        </div>
      </div>

      {alertOpen && (
        <Alert
          severity={alertMessage.includes("Failed") ? "error" : "success"}
          onClose={() => setAlertOpen(false)}
          sx={{ position: 'fixed', top: 16, right: 16, width: 'auto', zIndex: 1201 }}
        >
          {alertMessage}
        </Alert>
      )}

      <Dialog open={showModal} onClose={closeModal} fullWidth maxWidth="md">
        <DialogContent style={{ padding: '20px', position: 'relative' }}>
          <IconButton
            style={{ position: 'absolute', top: '10px', right: '10px' }}
            onClick={closeModal}
          >
            <CloseIcon />
          </IconButton>
          {selectedBub && (
            <div className="BubContai">
              <img src={getImageUrl(selectedBub.img)} alt="atelier_degustation" style={{ display: "block", margin: "0 auto", width: '300px' }} />
              <h3 className="Bub-titre">{selectedBub.titre}</h3>
              <div className="Bub-inf">
              <div className='partageBub' style={{ display: "flex", justifyContent: 'center', padding: "20px" }}>
                    <div>
                      <FacebookShareButton url={shareUrl} quote={selectedBub.titre} >
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#0965FE',
                          paddingRight: '5px'
                        }}>
                          <FacebookIcon  style={{color:"#fff" }} size={20} />
                          <h3 className='info-item'>Partage</h3>
                        </div>
                      </FacebookShareButton>
                    </div>
                    <div>
                      <LinkedinShareButton  url={shareUrl} >
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#0077B5',
                          paddingRight: '5px'
                        }}>
                          <LinkedInIcon  style={{color:"#fff" }} size={20} />
                          <h3 className='info-item'>Partage</h3>
                        </div>
                      </LinkedinShareButton>
                    </div>
                  </div>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FormAtelier;
