import React, { useEffect } from "react";
import BarheaderAdmin from "../BarheaderAdmin";
import NavBarAdmin from "../NavBarAdmin";
import "./css/faq.css";
import Deconnection from "../Deconnection";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const Faq = () => {
  const navigate = useNavigate();
  const { isAuthadmin } = useSelector((state) => state.admin);

  useEffect(() => {
    if (!isAuthadmin) navigate('/admin/login');
  }, [isAuthadmin, navigate]);
  return (
<>

<BarheaderAdmin />
      <NavBarAdmin />
    <Deconnection/>
      <div className="ConsultFAQ">
        <div className="ConsultFAQContainer">
          <button className="AccueilFAQ" onClick={()=>navigate('/admin/add-faq')}>Ajouter un FAQ</button>
          <button className="AccueilFAQ" onClick={()=>navigate('/admin/list-faq')}>Liste des FAQ</button>

        </div>
      </div>
      </>  )
}


export default Faq