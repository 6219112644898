import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCoach, getCoachVisivble } from "../../Redux/Slice/CoachSlice";
import image from "../../images/big_image_2.jpg";
import logo from "../../images/logo.jpg";

import "./css/abonner.css";
import { FaEarthAfrica } from "react-icons/fa6";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { GiPositionMarker } from "react-icons/gi";
import { SiRss } from "react-icons/si";
import { FaFacebookF } from "react-icons/fa";
import { BiLogoLinkedin } from "react-icons/bi";
import { ImYoutube2 } from "react-icons/im";
import { getImageUrl } from "../..";
import {
  Dialog
} from "@mui/material"; // Single import statement for all MUI components
import { TiArrowBackOutline } from "react-icons/ti";

const Profil = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { coachVisible } = useSelector((state) => state.coach);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCoachVisivble());
    console.log("coachVisible",coachVisible)

  }, [dispatch]);
  useEffect(() => {
    const handleScroll = () => {
      const elem = document.querySelector('.section-hero');
      const { top } = elem.getBoundingClientRect();
      if (elem) {
        const backgroundPositionY = -(top +25) * 0.6;
        elem.style.backgroundPosition = `50% ${backgroundPositionY}px`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const ProfilCoach = Array.isArray(coachVisible)
    ? coachVisible.find((coach) => coach._id === id)
    : null;

  const handleClose = () => {
    navigate("/coach/login")  };
console.log(ProfilCoach)
  return (
    <>
      <div
        className="Platforme_Profil section-hero"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div>
          <h2 className="ProfilTitre">Voici votre compte</h2>
        </div>
      </div>

      {ProfilCoach?.paye ? (
        <div className="PROFIL">
          <div className="profil-container">
            <div style={{ textAlign: "left" }} className="PROFIL_LEFT">
              <div className="Photo">
                <div className="RetourResult">
                  <TiArrowBackOutline />
                  <h1 className="Retour" onClick={() => navigate("/coach")}>
                    Retour aux Résultats
                  </h1>
                </div>{" "}
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img
                    src={getImageUrl(ProfilCoach.image)}
                    alt=" coach"
                    className="PhotoProfil"
                  />
                  <h1
                    style={{
                      fontSize: "16px",
                      color: "rgb(128,128,128)",
                      fontWeight: "300",
                    }}
                  >
                    {ProfilCoach.nom}
                  </h1>
                </div>
              </div>

              <div className="Contact">
                <div className="informationCOACH">
                  <h1 className="ProfilContact">Contact</h1>
                </div>
                <div className="informationCOACH">
                  <div className="information">
                    <FaEarthAfrica style={{ color: " rgb(255,205,51)" }} />
                    <h3 className="information-Item">
                      Site web: {ProfilCoach.site}
                    </h3>
                  </div>
                  <hr />
                  <div className="information">
                    <MdEmail style={{ color: " rgb(255,205,51)" }} />
                    <h3 className="information-Item">
                      E-mail: {ProfilCoach.email}
                    </h3>
                  </div>
                  <hr />
                  <div className="information">
                    <BsTelephoneFill style={{ color: " rgb(255,205,51)" }} />
                    <h3 className="information-Item">Tél: {ProfilCoach.num}</h3>
                  </div>
                  <hr />
                  <div className="information">
                    <GiPositionMarker style={{ color: " rgb(255,205,51)" }} />
                    <h3 className="information-Item">
                      Gouvernorat: {ProfilCoach.gouv}
                    </h3>
                  </div>
                  <hr />
                  <div className="information">
                    <SiRss style={{ color: " rgb(255,205,51)" }} />
                    <h3 className="information-Item">Réseaux sociaux:</h3>
                  </div>
                </div>
                <div className="information">
                  <FaFacebookF
                    style={{
                      color: "rgb(255,205,51)",
                      marginRight: "20px",
                      fontSize: "20px",
                    }}
                  />
                  <BiLogoLinkedin
                    style={{
                      color: "rgb(255,205,51)",
                      marginRight: "20px",
                      fontSize: "20px",
                    }}
                  />
                  <ImYoutube2
                    style={{
                      color: "rgb(255,205,51)",
                      marginRight: "20px",
                      fontSize: "30px",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="PROFIL_Right ">
              <h3 className="domaines-coaching-title titre">
                Domaines de Coaching
              </h3>
              <div className="domaines-intervention list">
                {ProfilCoach.domain &&
                  ProfilCoach.domain.map((domaine, index) => (
                    <h3 className="ProfilDomaine" key={index}>
                      {domaine}
                    </h3>
                  ))}
              </div>

              <h2 className="domaines-coaching-title titre">Brève Bio</h2>
              <div className="bio">
                <p className="bioCoach">{ProfilCoach.bio}</p>
              </div>

              <h2 className="Méthodes de Coaching-title titre list">
                Méthodes de Coaching
              </h2>
              <div className="Méthodes-Coaching list">
                {ProfilCoach.method &&
                  ProfilCoach.method.map((MethCoach, index) => (
                    <h3 className="ProfilMethode" key={index}>
                      {MethCoach}
                    </h3>
                  ))}
              </div>

              <h2 className="Type-de-Client-title titre list">
                Type de Client
              </h2>
              <div className="Type-de-Client list">
                <h3 className="ProfilType">{ProfilCoach.type_client}</h3>
              </div>

              <h2 className="Langues titre list">Langues</h2>
              <div className="Langues list">
                {ProfilCoach.langue &&
                  ProfilCoach.langue.map((Lang, index) => (
                    <h3 className="ProfilLangues" key={index}>
                      {Lang}
                    </h3>
                  ))}
              </div>

              <h2 className="tarif titre list">
                Tarif préférentiel (réduction de 10% pour les clients de la
                plateforme)
              </h2>
              <div className="Tarif list">
                {ProfilCoach.paye ? (
                  <h3 className="ProfilTarif">oui</h3>
                ) : (
                  <h3 className="ProfilTarif">non</h3>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="PROFIL">
          <div className="profil-container">
            <div style={{ textAlign: "left" }} className="PROFIL_LEFT">
              <div className="Photo">
                <div className="RetourResult">
                  <TiArrowBackOutline />
                  <h1 className="Retour" onClick={() => navigate("/coach")}>
                    Retour aux Résultats
                  </h1>
                </div>
                <img
                  src={getImageUrl(ProfilCoach?.image)}
                  alt=" coach"
                  className="PhotoProfil"
                />
              </div>
            </div>

            <div className="PROFIL_Right">
              <Dialog open={true}   sx={{
    "& .MuiDialog-paper": {
      borderRadius: "15px", // Adjust the value for more or less roundness
    },
  }}>
                <img
                  src={logo}
                  alt="MonCoach Logo"
                  style={{ width: "200px", display: "block", margin: "20px auto 20px  auto" }}
                />
                <p
                  style={{ textAlign: "center", padding: "15px",color:"rgb(128, 128, 128)" ,lineHeight:'32px',fontFamily:'"Segoe UI", sans-serif'}}
                >
                  L'accès à ces informations est réservé pour les coaches
                  abonnés.
              <br/>
                  Vous êtes un coach et vous voulez activer votre abonnement
                  annuel et rester connecté avec vos clients, cliquez ici !
                </p>
                <button onClick={handleClose} className="activer">
                  Activer mon abonnement
                </button>
              </Dialog>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profil;
