import React, { useEffect, useRef } from "react";
import './css/style.css';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from '../../Redux/Slice/AdminSlice';

const Login = () => {
  const user = useRef();
  const password = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { isAuthadmin } = useSelector((state) => state.admin);

  useEffect(() => {
console.log(isAuthadmin)
    if (isAuthadmin==="false")
      
      navigate("/admin/login");

  }, [isAuthadmin, navigate]);

  return (
    <div className="Login">
      <div className="containe">
        <form>
          <h1 className="Loginconnection">Connexion</h1>
          <label className="logintitle">
            Nom d'utilisateur
          </label>
          <input 
            type="text" 
            placeholder="Entrer le nom d'utilisateur" 
            ref={user} 
            className="inputadmin" 
          />

          <label className="logintitle">
            Mot de passe
          </label>
          <div className="input-container">
            <input 
              placeholder="Entrer le mot de passe" 
              ref={password} 
              className="inputadmin password-input" 
            />
          
          </div>

          <h2 href="#" className="mdpoublie">Mot de passe oublié ?</h2>
          <input 
            type="submit" 
            value="LOGIN" 
            className="loginAdmin"   
            onClick={(e) => {
              e.preventDefault();
              dispatch(login({ nom_utilisateur: user.current.value, mot_de_passe: password.current.value }));
              navigate('/admin/Accueil');
            }}
          />
        </form>
      </div>
    </div>
  );
};

export default Login;
