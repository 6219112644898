import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../Redux/Slice/CoachSlice";
import image from "../../images/big_image_2.jpg";
import Newsletter from "./Newsletter";
import Footer from "./Footer";
import './css/login.css';

const LoginCoch = () => {
  const { isAuth, mssg } = useSelector((state) => state.coach);

  const email = useRef();
  const password = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth)
      
      navigate("/coach/profil");

  }, [isAuth, navigate]);

  const handleLoginFormSubmit = (e) => {
    e.preventDefault();

    dispatch(login({ email: email.current.value, pwd: password.current.value }));
    console.log({mssg})

  };

  return (
    <>
      <div
        className="PlatformeLogin"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div>
          <p className="TitreLogin">
            Bienvenue sur votre plateforme MonCoach.tn
          </p>
        </div>
      </div>

      <div className="containerLogin">
        <form className="Form-Login" onSubmit={handleLoginFormSubmit}>
          <label className="ConnectLogin">Connectez-vous:</label>
          
          {/* Display success or error message */}
          {mssg && typeof mssg === 'string' && (
            <div className={`message ${mssg.toLowerCase().includes("successful") ? "success" : "error"}`}>
              {mssg}
            </div>
          )}

          <div className="input-Login">
            <input
              type="email"
              name="email"
              className="input"
              ref={email}
              placeholder="Login (Votre adresse mail)"
              style={{ paddingLeft: "10px" }}
            />
          </div>
          <div className="input-Login password-container">
            <input
              type="password"
              name="password"
              ref={password}
              className="input password-input"
              placeholder="Mot de passe"
              style={{ paddingLeft: "10px" }}
            />
          </div>
          <Link to="/forget">
            <label className="label-Login">Mot de passe oublié?</label>
          </Link>
          
          <button type="submit" className="Connecter">Connecter</button>
        </form>
      </div>

      <Newsletter />
      <Footer />
    </>
  );
};

export default LoginCoch;
